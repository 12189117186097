import React from 'react';
import './styles/styles.scss';
import {
    jarallax,
    jarallaxElement,
} from 'jarallax';
import $ from 'jquery';
import AboutPanel from './components/AboutPanel/AboutPanel';
import CaptainsPanel from './components/CaptainsPanel/CaptainsPanel';
import TrailerPanel from './components/TrailerPanel/TrailerPanel';
import PreorderPanel from './components/PreorderPanel/PreorderPanel';
import DesignTeamPanel from './components/CaptainsPanel/DesignTeamPanel';
import Menu from './components/Menu/Menu';
import {preorder_url} from "./utils";


export default class PreorderSite extends React.Component {
    constructor(props) {
        super(props);
        this.panels = [
            <AboutPanel
                id="about"
                key="about"
                title="About"
                beat={7000}
                unpause_beat={2000}
            />,
            <TrailerPanel
                id="trailer"
                key="trailer"
                title="Trailer"
                code="ffGBsLKhD40"
            />,
            <CaptainsPanel
                id="captains"
                key="captains"
                title="Captains"
            />,
            <PreorderPanel
                id="order"
                key="order"
                title="Order"
            />,
            <DesignTeamPanel
                id="team"
                key="team"
                title="Design Team"
                is_last={true}
            />
        ];
    }

    componentDidMount() {
        jarallaxElement();
        jarallax(document.querySelectorAll('body'), {
            speed: .2,
            imgSrc: 'sea_bg.jpg'
        });
        window.addEventListener("resize", ()=>this.handleResize());
        window.addEventListener("scroll", ()=>this.handleScroll());
    }

    goPreorder() {
        window.open(preorder_url, '_blank');
    }

    toggleMenu() {
        $(".preorder-site-header-menu").slideToggle();
    }

    handleMenuSelect(id = null) {
        if(id) {
            $('html, body').animate({
                scrollTop: $("#"+id).offset().top
            }, 500);
            this.handleResize();
        }
    }

    handleResize() {
        if(document.documentElement.clientWidth < 800) {
            $(".preorder-site-header-menu").hide();
        } else {
            $(".preorder-site-header-menu").show();
        }
    }

    handleScroll() {
        let active_item = null;
        for(let one_panel of this.panels) {
            if($("#"+one_panel.props.id).offset().top - 200 < $(window).scrollTop()) {
                active_item = one_panel.props.id;
            }
        }
        $('.menu-item').removeClass('active');
        $('.menu-item-'+active_item).addClass('active');
    }

    handleNotificationClose() {
      $('.preorder-site-notification').slideUp(500);
    }

    render() {

        return(
            <div className="preorder-site">
                <div className="preorder-site-header-container">
                    <div className="preorder-site-header">
                        <div className="preorder-site-header-menu">
                            <Menu panels={this.panels} onMenuSelect={(id)=>this.handleMenuSelect(id)} />
                        </div>
                        <div className="preorder-site-header-group">
                            <div className="preorder-site-header-bars">
                                <i className="fas fa-bars" onClick={()=>this.toggleMenu()}></i>
                            </div>
                            <div
                                className="preorder-site-header-bonus"
                                onClick={()=>this.goPreorder()}
                            >
                                <h2>Order Now!</h2>
                                <div>$59.95 and Free US Shipping*</div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="preorder-site-content-container">
                    <div className="preorder-site-logos">
                        <img src="logo.png" className="fw-logo" alt="Forgotten Waters" />
                        <img src="crossroads.png" className="cr-logo" alt="A Crossroads Game" />
                    </div>
                    <hr />
                    <div className="preorder-site-notification">
                        <i
                            className="fa fa-times preorder-site-notification-close-button"
                            onClick={()=>this.handleNotificationClose()}
                        ></i>
                        <h2>Update!</h2>
                        <hr />
                        <div>
                            All first print run copies will
                            now also receive a free promotional card and counter organizer!
                        </div>
                        <img
                          src="images/promo.png"
                          alt="Promo card and counter organizer"
                          className="preorder-site-notification-promo"
                        />
                    </div>
                    <div className="preorder-site-panels-container">
                        {this.panels}
                    </div>
                </div>
                <div className="preorder-site-footer">
                    <div className="newsletter-container">
                        <iframe title="Newsletter" src="https://cdn.forms-content.sg-form.com/229c9f6b-45f6-11ea-bddc-12668d41bbc0"/>
                    </div>
                    <div>
                        <a href="https://www.plaidhatgames.com">
                            <img className="footer-logo" src="images/phg.png" alt="Plaid Hat Games" />
                        </a>
                        <br />Copyright &copy; 2020 Plaid Hat Games
                    </div>
                </div>
            </div>
        );
    }
}
