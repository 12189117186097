import React from 'react';
import './PreorderPanel.scss';
import Panel from '../Panel/Panel';
import {preorder_url} from "../../utils";

export default class PreorderPanel extends Panel {
    goPreorder() {
        window.open(preorder_url, '_blank');
    }

    getContent() {
        return (
            <div className="preorder-container">
                <div className="content-half">
                    <div className="preorder-details">
                        *Order Forgotten Waters from plaidhatgames.com to receive FREE SHIPPING in
                        the contiguous United&nbsp;States.
                    </div>
                    <hr />
                    <div className="preorder-promo-announce">
                        All first-run copies will now also receive a free promotional card&nbsp;and&nbsp;counter&nbsp;organizer!
                    </div>
                    <img src="images/promo_swoop.png" alt="Promo card and counter organizer" className="preorder-treasure" />
                    <div className="preorder-promo">
                        <a
                            href="https://www.plaidhatgames.com/images/games/forgotten-waters/rules.pdf"
                            target="_blank" rel="noopener noreferrer"
                        >
                            Download the rulebook&nbsp;here!
                        </a>
                    </div>
                    <div className="preorder-price-container">
                        <div className="preorder-sale-price">
                            $59.95
                        </div>
                    </div>
                    <button
                        className="preorder-button"
                        onClick={()=>this.goPreorder()}
                    >
                        Order Now!
                    </button>
                </div>
                <div className="content-half">
                    <img src="images/components_v.png" alt="Forgotten Waters components" className="preorder-components" />
                </div>
            </div>
        );
    }
}
